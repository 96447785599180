import { DeleteOutlined, EditOutlined, PictureOutlined } from "@ant-design/icons";
import { Card, Descriptions, Space, Typography, Spin, Popconfirm } from "antd";
import Meta from "antd/lib/card/Meta";
import { useContext, useEffect, useState } from "react";
import { PhotoService, WellService, Well } from "../../client";
import UserContext from "../data/UserContext";
import ImageGallery from "./ImageGallery";
import WellImage from "./WellImage";
import WellForm from "./WellForm";

type WellInfoProps = {
    well: Well
    options: string[]
}
const { Paragraph } = Typography;

export default function WellInfo({ well, options }: WellInfoProps) {
    const { token, setUpdateWells } = useContext(UserContext);
    const [photoInfo, setPhotoInfo] = useState([])
    const [loadedInfo, setLoadedInfo] = useState(false)
    const [viewGallery, setViewGallery] = useState(false)
    const [viewEdit, setViewEdit] = useState(false)
    const [updatePhoto, setUpdatePhoto] = useState(false)
    useEffect(() => {
        PhotoService.getPhotosList(well.id, token).then((resp) => {
            setPhotoInfo(resp)
            setLoadedInfo(true)
        })

    }, [well, token, updatePhoto])

    const deleteWell = (id) => {
        WellService.deleteWell(id, token)
        setUpdateWells((state) => { return !state })
    }
    if (!loadedInfo) {
        return <Spin style={{ width: 300 }} />
    }
    return <>
        {viewEdit ? <WellForm
            title="Редактирование"
            well={well}
            onFinish={(values) => {
                let { name, description, params } = values;
                if (!params) {
                    params = []
                }
                WellService.updateWell(well.id, token, { coords: well.coords, name, description, params })
                setViewEdit(false)
                setUpdateWells((state) => !state)
            }
            }
            onClose={() => { setViewEdit(false) }}
            options={options} /> : null}
        <ImageGallery
            photoInfo={photoInfo}
            visible={viewGallery}
            setVisible={setViewGallery}
            idWell={well.id}
            setUpdatePhoto={setUpdatePhoto} /><Card
                style={{ width: 300 }}
                cover={
                    Object.keys(photoInfo).length ? <WellImage
                        preview={false}
                        idPhoto={photoInfo[0].id}
                        idWell={well.id}
                        onClick={() => { setViewGallery(true) }}
                    /> : null
                }
                actions={
                    [
                        <PictureOutlined key="gallery" onClick={() => { setViewGallery(true) }} />,
                        <EditOutlined key="edit" onClick={() => { setViewEdit(true) }} />,
                        <Popconfirm
                            title="Удалить колодец?"
                            onConfirm={() => { deleteWell(well.id) }}
                        >
                            <DeleteOutlined key="ellipsis" />
                        </Popconfirm>,
                    ]}
            >
            <Space direction="vertical">
                <Meta
                    title={<Typography.Text strong>{well.name}</Typography.Text>}
                    description={well.description}
                />
                <Descriptions size="small" column={1}>
                    {well.params.map(
                        ({ name, value }, index) => <Descriptions.Item key={index} label={name}>{value}</Descriptions.Item>
                    )}
                    <Descriptions.Item key="coords" label="Координаты">
                        <Paragraph copyable style={{ whiteSpace: "nowrap" }}>{`${well.coords.lat}, ${well.coords.lng}`}</Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </Space>
        </Card >
    </>
}
