/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_well_photo } from '../models/Body_upload_well_photo';
import type { PhotoInfo } from '../models/PhotoInfo';
import type { PhotoResp } from '../models/PhotoResp';
import type { Resp } from '../models/Resp';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PhotoService {

    /**
     * Get Photos List
     * Получение списка фото
     * @param idWell
     * @param token
     * @returns PhotoInfo Successful Response
     * @throws ApiError
     */
    public static getPhotosList(
        idWell: number,
        token: string,
    ): CancelablePromise<Array<PhotoInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_crocodile/wells/{id_well}/photo',
            path: {
                'id_well': idWell,
            },
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Well Photo
     * Загрузка фото колодца
     * @param idWell
     * @param token
     * @param requestBody
     * @returns Resp Successful Response
     * @throws ApiError
     */
    public static uploadWellPhoto(
        idWell: number,
        token: string,
        requestBody: Body_upload_well_photo,
    ): CancelablePromise<Resp> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_crocodile/wells/{id_well}/photo',
            path: {
                'id_well': idWell,
            },
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Photo
     * Получение фото
     * @param idWell
     * @param idPhoto
     * @param token
     * @returns PhotoResp Successful Response
     * @throws ApiError
     */
    public static getPhoto(
        idWell: number,
        idPhoto: number,
        token: string,
    ): CancelablePromise<PhotoResp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_crocodile/wells/{id_well}/photo/{id_photo}',
            path: {
                'id_well': idWell,
                'id_photo': idPhoto,
            },
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Well Photo
     * Удаление фото колодца
     * @param idWell
     * @param idPhoto
     * @param token
     * @returns Resp Successful Response
     * @throws ApiError
     */
    public static deleteWellPhoto(
        idWell: number,
        idPhoto: number,
        token: string,
    ): CancelablePromise<Resp> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api_crocodile/wells/{id_well}/photo/{id_photo}',
            path: {
                'id_well': idWell,
                'id_photo': idPhoto,
            },
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}