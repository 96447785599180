import { Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { PhotoService } from "../../client"
import { Image } from "antd";
import UserContext from "../data/UserContext";
const Buffer = require('buffer/').Buffer

export default function WellImage({ idPhoto, idWell, onClick, preview }) {
    const { token } = useContext(UserContext)
    const [photo, setPhoto] = useState(<Spin />)
    useEffect(() => {

        PhotoService.getPhoto(idWell, idPhoto, token).then(
            ({ base64, description, md5 }) => {
                let type = "png"
                try {
                    let data = Buffer.from(base64, 'base64')
                    if (data[0] === 255 && data[1] === 216) {
                        type = "jpeg"
                    }
                } catch (err) {
                    console.error(err)
                }
                setPhoto(<Image preview={preview}
                    width={300}
                    onClick={onClick}
                    src={`data:image/${type};base64, ${base64}`} />)
            }
        )
    }, [idPhoto, idWell, onClick, preview, token])
    return photo
}