import { ConfigProvider } from 'antd';
import { BrowserRouter as Router } from "react-router-dom";


import moment from 'moment-timezone';
import 'moment/locale/ru';
import './App.less';
import ruRU from 'antd/lib/locale/ru_RU';
import AppLayout from './Layout'
moment.locale('ru');
moment.tz.setDefault("Europe/Moscow")
export default function App() {
  return (
    <Router>
      <ConfigProvider locale={ruRU}>
        <AppLayout />
      </ConfigProvider>
    </Router>
  );
}



