import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Form, Input, Modal } from "antd";
const { TextArea } = Input;

export default function WellForm({ onFinish, onClose, options, title, well }) {
    const [form] = Form.useForm()
    return <Modal
        title={title}
        centered
        visible={true}
        onOk={() => { form.submit() }}
        onCancel={onClose}
    >
        <Form
            name="createWell"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            form={form}
            initialValues={{ ...well }}
            autoComplete="off"
        >
            <Form.Item
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Необходимо название' }]}
            >
                <Input placeholder="Колодец #1" />
            </Form.Item>
            <Form.Item
                label="Описание"
                name="description"
                rules={[{ required: true, message: 'Необходимо описание' }]}
            >
                <TextArea rows={4} placeholder="Вонючий" />
            </Form.Item>
            <Form.List name="params">
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => {
                            return (
                                <Form.Item key={key} noStyle>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        noStyle
                                    >
                                        <AutoComplete
                                            style={{ width: 'calc(50% - 20px)' }}
                                            options={options}
                                            placeholder="Параметр"
                                            //@ts-ignore
                                            filterOption={(inputValue, option) => {
                                                //@ts-ignore
                                                return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'value']}
                                        noStyle
                                    >
                                        <Input placeholder="Значение" style={{ width: '50%' }} />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        style={{ paddingLeft: 5 }}
                                        className="dynamic-delete-button"
                                        onClick={() => remove(name)}
                                    />
                                </Form.Item>
                            )
                        })}
                        <Form.Item>
                            <Button
                                style={{ marginTop: 20 }}
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            >
                                Добавить параметр
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    </Modal >
}