/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewWell } from '../models/NewWell';
import type { Resp } from '../models/Resp';
import type { Well } from '../models/Well';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WellService {

    /**
     * Get Wells
     * Получение колодцев
     * @param token
     * @returns Well Successful Response
     * @throws ApiError
     */
    public static getWells(
        token: string,
    ): CancelablePromise<Array<Well>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_crocodile/wells',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Well
     * Создание колодца
     * @param token
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static createWell(
        token: string,
        requestBody: NewWell,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_crocodile/wells',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Well
     * Обновление колодца
     * @param idWell
     * @param token
     * @param requestBody
     * @returns Resp Successful Response
     * @throws ApiError
     */
    public static updateWell(
        idWell: number,
        token: string,
        requestBody: NewWell,
    ): CancelablePromise<Resp> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api_crocodile/wells/{id_well}',
            path: {
                'id_well': idWell,
            },
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Well
     * Удаление колодца
     * @param idWell
     * @param token
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteWell(
        idWell: number,
        token: string,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api_crocodile/wells/{id_well}',
            path: {
                'id_well': idWell,
            },
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}