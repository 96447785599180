import { Button, Card, Input, Select } from "antd"
import { useEffect, useMemo, useState } from "react"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Well } from "../../client";
import NewWell from "./NewWell";
import { ScaleControl } from 'react-leaflet'
import { iconWell } from "./Icon";
import WellInfo from "./WellInfo";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Control from 'react-leaflet-custom-control'
const { Search } = Input;
const { Option } = Select;
const gridStyle = {
    width: '100%',
    textAlign: 'center' as const,
};

function HandleMap({ map, options }) {
    const [position, setPosition] = useState(null)
    const [showMenu, setShowMenu] = useState(false)
    const [menuCoords, setMenuCoords] = useState(null)
    const [showWellPopup, setShowWellPopup] = useState(false)

    const createWellButton = () => {
        setShowMenu(false)
        setShowWellPopup(true)
    }

    const showContextMenu = (event) => {
        setShowMenu(true)
        setMenuCoords(event.containerPoint)
        setPosition(event.latlng)
    }
    const hideContextMenu = () => {
        setShowMenu(false)
    }
    useEffect(() => {
        if (map) {
            map.on('contextmenu', showContextMenu)
            map.on('mousedown', hideContextMenu)
            return () => {
                map.off('contextmenu', showContextMenu)
                map.off('mousedown', hideContextMenu)
            }
        }
    }, [map])

    if (showMenu) {
        return <Card size="small" title="Создание объектов"
            extra={<Button type="link" onClick={hideContextMenu} >Отмена</Button>}
            style={{
                width: 300,
                position: "absolute",
                top: menuCoords.y,
                left: menuCoords.x,
                zIndex: 10
            }}>
            <Card.Grid style={gridStyle} onClick={createWellButton}>Создать колодец</Card.Grid>
            <Card.Grid style={gridStyle}>Начать рисовать трубу</Card.Grid>
        </Card>
    }
    if (showWellPopup) {
        return <NewWell onClose={() => setShowWellPopup(false)} position={position} options={options} />
    }
    return null
}

function Map({ wells }) {

    const [map, setMap] = useState(null)
    const onSearch = (well_id) => {
        let [well] = wells.filter(
            (elem) => {
                return elem.id === well_id
            }
        )
        map.flyTo(well.coords, 18)
    }
    const displayMap = useMemo(
        () => {
            let options = []
            for (let well of wells) {
                for (let param of well.params) {
                    options.push({ value: param.name })
                }
            }
            return <>
                <HandleMap map={map} options={options} />
                <MapContainer center={[61.692797, 33.619517]}
                    ref={setMap}
                    zoom={13}
                    maxZoom={21}
                    scrollWheelZoom={true}
                    style={{
                        width: "100%",
                        height: "100%",
                        minHeight: "calc(100vh - 64px)",
                        zIndex: 0
                    }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxNativeZoom={19}
                        maxZoom={21}
                    />

                    <MarkerClusterGroup>
                        {wells.map((well: Well) => {
                            return <Marker key={well.id} position={[well.coords.lat, well.coords.lng]} icon={iconWell}>
                                <Popup>
                                    <WellInfo well={well} options={options} />
                                </Popup>
                            </Marker>
                        })}
                    </MarkerClusterGroup>
                    <ScaleControl metric={true} imperial={false} position="bottomright" />
                    <Control prepend position='topright'>
                        <Select
                            showSearch
                            placeholder="Введите название"
                            onSelect={onSearch}
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA!.children as unknown as string)
                                    .toLowerCase()
                                    .localeCompare((optionB!.children as unknown as string).toLowerCase())
                            }
                        >
                            {wells.map((well: Well) => {
                                return <Option key={well.id} value={well.id}>{well.name}</Option>
                            })
                            }
                        </Select>
                    </Control>
                </MapContainer>
            </>
        },
        [wells, map],
    )
    return displayMap
}

export default Map;