import { useContext } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, message } from 'antd';
import UserContext from '../data/UserContext';
import sha512 from 'crypto-js/sha512';
import { AuthService } from '../../client';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const Login = () => {
    const { setToken } = useContext(UserContext);
    const onFinish = (values) => {
        let { password, login, remember } = values;
        password = sha512(password).toString();
        AuthService.login({ login, password }).then((data) => {
            let token = data.user_token;
            setToken(token)
            if (remember) {
                localStorage.setItem("token", token)
            }
        }).catch((err) => {
            if (err.status === 500) {
                message.error("Неверный логин или пароль");
            } else {
                message.error("Внутренняя ошибка сервера. Попробуйте позже или обратитесь к администратору");
            }
        })


    };


    return <Row align="middle" justify="center" style={{ height: "100vh" }}>
        <Col xs={18} sm={8} >
            <Form
                {...layout}
                className="login-form"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Логин"
                    name="login"
                    rules={[{ required: true, message: "Введите логин" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: "Введите пароль" }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                    <Checkbox>Запомнить меня</Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </Col>
    </Row>
};
export default Login;