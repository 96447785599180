import { useState, useEffect } from "react";
import { Col, Layout, Menu, Row, Spin } from 'antd';
import { Link } from "react-router-dom";
import UserContext from './components/data/UserContext'
import Map from './components/visual/Map'
import { WellService, Well, ApiError } from './client/index'
import Login from "./components/visual/Login";
const { Header, Content } = Layout;

function AppLayout() {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [updateWells, setUpdateWells] = useState(false);
    const is_auth = Boolean(token);
    const [loaded, setLoaded] = useState(false)
    const [wells, setWells] = useState<Well[]>([])
    const logout = () => {
        localStorage.removeItem("token")
        setToken(null)
    }
    useEffect(() => {
        if (is_auth) {
            WellService.getWells(token).then((resp) => {
                setWells(resp)
                setLoaded(true)
            }).catch((err: ApiError) => {
                if (err.status === 401) {
                    logout()
                }
            })
        }
    }, [updateWells, token, is_auth])
    const headItems = [{
        key: "Logo",
        icon: <img alt="logo" className="icon-50" src={`/logo.svg`}></img>
    },
    {
        key: "Logout",
        label: <Link to="/" onClick={logout}>
            Выйти
        </Link>,
        style: { marginLeft: 'auto' }
    }]
    return (<UserContext.Provider value={{ token, setToken, setUpdateWells }}>
        {is_auth ?
            <Layout>
                <Header>
                    <Menu selectable={false} theme="dark" mode="horizontal" items={headItems} />
                </Header>
                <Content>
                    <Row>
                        <Col span={24} >
                            {loaded ? <Map wells={wells} /> : <Spin />}
                        </Col>
                    </Row>
                </Content>
            </Layout> : <Login />}
    </UserContext.Provider >)
}
export default AppLayout;
