import L from 'leaflet';

const iconWell = new L.Icon({
    iconUrl: 'well.png',
    iconRetinaUrl: 'well.png',
    iconAnchor: null,
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 30),
});

export { iconWell };