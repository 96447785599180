import { message } from "antd";
import { useContext } from "react";
import { WellService } from "../../client";
import UserContext from "../data/UserContext";
import WellForm from "./WellForm";

export default function NewWell({ position, onClose, options }) {
    const { token, setUpdateWells } = useContext(UserContext);
    const createWell = (values) => {
        console.log('Received values of form:', values);
        let { name, description, params } = values;
        if (!params) {
            params = []
        }
        WellService.createWell(token, { coords: position, name, description, params }).then(() => {
            onClose()
            setUpdateWells((state) => { return !state })
        }).catch((err) => {
            if (err.status === 400) {
                message.error("Указаны недопустимые символы")
            } else {
                message.error("Ошибка создания ")
            }
        })
    }


    return <WellForm onFinish={createWell} onClose={onClose} options={options} title={"Создание колодца"} well={{}} />
}