import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { Image, Drawer, Col, Row, Space, Popconfirm, message, Upload } from "antd";
import { useContext } from "react";
import { PhotoService } from "../../client";
import UserContext from "../data/UserContext";
import md5 from 'crypto-js/md5';
import WellImage from "./WellImage";
const { Dragger } = Upload;

const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default function ImageGallery({ photoInfo, visible, setVisible, idWell, setUpdatePhoto }) {
    const { token } = useContext(UserContext);
    const deletePhoto = (idPhoto) => {
        PhotoService.deleteWellPhoto(idWell, idPhoto, token).then(() => {
            setUpdatePhoto((state) => { return !state })
        })
    }

    const uploadImage = async (options) => {
        let { onSuccess, onError, file, onProgress } = options
        toBase64(file).then((base64: string) => {
            const head = base64.indexOf("base64")
            base64 = base64.substring(head + 7)
            PhotoService.uploadWellPhoto(idWell, token, { md5: md5(base64).toString(), desctiption: "", base64 }).then(
                () => {
                    onProgress({ percent: 100 }, file)
                    onSuccess(file)
                    setUpdatePhoto((state) => { return !state })
                }
            ).catch((err) => {
                const error = new Error('Some error');
                onError({ event: error });
                message.error("Ошибка загрузки")
            }
            )
        })
    };
    return <Drawer title="Галерея" placement="right" onClose={() => { setVisible(false) }} visible={visible}>
        {photoInfo.length ? <Image.PreviewGroup>
            <Col>
                {
                    photoInfo.map(({ id }, i) => {
                        return <Row key={id}>
                            <Space align="start">
                                <WellImage idPhoto={id}
                                    preview={{}}
                                    idWell={idWell}
                                    onClick={null}
                                />
                                <Popconfirm
                                    title="Удалить фотографию?"
                                    onConfirm={() => deletePhoto(id)}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Space>
                        </Row>
                    })
                }
            </Col>
        </Image.PreviewGroup> : null}
        <Dragger name='file'
            multiple={false}
            accept="image/*"
            style={{ minHeight: 150, maxHeight: 150, marginTop: 20 }}
            customRequest={uploadImage}
            showUploadList={false}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Нажмите или перетащите фотографии</p>
        </Dragger>
    </Drawer>
}